// import { phone as PhoneValidator } from 'phone';

export function emailIsValid(email) {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    )
  ) {
    return true;
  }
  return false;
}

// export function phoneIsValid(phone) {
//   if (PhoneValidator(phone).isValid) {
//     return true;
//   }
//   return false;
// }

export function fullNameIsValid(fullName) {
  if (/^([\w]{3,})+\s+([\w\s]{3,})+$/.test(fullName)) {
    return true;
  }
  return false;
}

export function passwordIsValid(password) {
  if (password.length >= 8) {
    return true;
  }
  return false;
}
