import * as React from 'react';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import CustomizedDialogs from './Dialog';

export default function UploadProgressDialog({ open, setOpen, progress }) {
  return (
    <CustomizedDialogs
      title={'Uploading in progress'}
      open={!!open}
      setOpen={setOpen}
      canClose={false}
    >
      <CircularProgressWithLabel progress={progress} size={120} />
    </CustomizedDialogs>
  );
}
