//
import Accordion from './Accordion';
import Alert from './Alert';
import Button from './Button';
import TextField from './TextField';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    new Button(theme),
    new Accordion(theme),
    new TextField(theme),
    // Dialog(theme),
    new Alert(theme),
  );
}
